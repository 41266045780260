import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Badge from 'react-bootstrap/Badge';
import * as Icon from 'react-feather';
import { getCookie } from '../utils/localStorage';

import Logo from '../public/images/logo-dark.svg';

export default function Header({ isOpen, toggleSidebar }) {
  const [userData, setUserData] = useState(false);
  const [uid, setUid] = useState(false);

  useEffect(() => {
    async function fetchUserData() {
      const isLogin = getCookie('isLogin');
      if (!isLogin) return;  // Eğer kullanıcı giriş yapmamışsa işlem yapma

      const uid = getCookie('uid');
      const eposta = getCookie('eposta');
      const auth = getCookie('auth');

      setUid(uid);

      try {
        const response = await fetch(`https://api.yatirimnedir.com/giris-kontrol.php?uid=${uid}&eposta=${eposta}&auth=${auth}`);
        const data = await response.json();
        if (data.status) {
          setUserData(true);
        } else {
          setUserData(false);
        }
      } catch (error) {
          console.error('API isteğinde hata oluştu:', error);
          setUserData(false);
      }
    }

    fetchUserData();
  }, []);  // Bağımlılık listesi boş, yani sadece bileşen mount edildiğinde çalışır

  return (
    <>
      <header className="main-header d-flex justify-content-between">
        <Link prefetch={false} href='/' className='mobile-logo'>
          <Image
            src={Logo}
            alt='Logo'
            quality={100}
            title="Yatırım Nedir: Temettü Hisseleri - Yatırım Fonları - BES Fonları Analiz"
          />
        </Link>

        <nav className="top-nav">
          <ul className="menu-group">
            <li className="sub-menu open-sub-menu">
              <Link prefetch={false} className="menu-link" href="/">
                Anasayfa
              </Link>
            </li>
            <li className="sub-menu open-sub-menu">
              <a className="menu-link" href="#">
                Hakkında
                <Icon.ChevronDown width={14}/>
              </a>
              <div className="top-menu-group-wrapper">
                <ul className="top-menu-group">	
                  <li>
                    <Link prefetch={false} href="/cekince-bildirimi" className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <span>Yasal Uyarı</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link prefetch={false} href="/iletisim" className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <span>İletişim</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="sub-menu open-sub-menu">
              <Link prefetch={false} className="menu-link" href="https://forms.gle/F9kKYda6hAVdUkCGA" target="_blank">
                İstek ve Hata Bildirimi
              </Link>
            </li>
            <li className="sub-menu open-sub-menu">
              <Link prefetch={false} className="menu-link" href="/version">
                Yeni Özellikler
              </Link>
            </li>
            <li className="sub-menu open-sub-menu">
              <Link prefetch={false} className="menu-link" href="/blog">
                Blog
              </Link>
            </li>
            <li className="sub-menu open-sub-menu">
              <Link prefetch={false} className="menu-link" href="/mobile-app">
                Mobil Uygulama <Badge pill bg="success">Yeni</Badge>
              </Link>
            </li>
          </ul>
        </nav>

        <div className="header-tools d-flex align-items-center">
          {!userData &&
            <>
              <Link prefetch={false} href='/giris-yap' className="btn btn-light btn-sm me-1 me-lg-3">Giriş Yap</Link>
              <Link prefetch={false} href='/uye-ol' className="btn btn-primary btn-sm">Üye Ol</Link>
            </>
          }
          {userData &&
            <>
              <DropdownButton id="dropdown-basic-button" title={`${getCookie('ad')} ${getCookie('soyad')}`}>
                <Dropdown.Item href="/fon-portfoyum">Fon Portföyüm</Dropdown.Item>
                <Dropdown.Item href="/temettu-portfoyum">Temettü Portföyüm</Dropdown.Item>
                <Dropdown.Item href="/favorilerim">Favorilerim</Dropdown.Item>
                <Dropdown.Item href="/hesap-ayarlari">Hesap Ayarları</Dropdown.Item>
                {uid === '1' &&
                  <>
                    <Dropdown.Item href="/sosyal-medya-fon-karti">Fon Kartı S.M.</Dropdown.Item>
                    <Dropdown.Item href="/sosyal-medya-fon-ceyreklik-getiri">Fon Çeyreklik S.M.</Dropdown.Item>
                    <Dropdown.Item href="/sosyal-medya-temettu-karti">Temettu Kartı S.M.</Dropdown.Item>
                  </>
                }
                <Dropdown.Item href="/cikis-yap">Çıkış Yap</Dropdown.Item>
              </DropdownButton>
            </>
          }
          <Link prefetch={false} href='#' className={`sidebar-menu-trigger ${isOpen ? 'active' : ''}`} onClick={toggleSidebar}><span>Menu</span></Link>
        </div>
      </header>
    </>
  )
}
