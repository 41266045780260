import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Scrollbars } from 'react-custom-scrollbars';
import Badge from 'react-bootstrap/Badge';
import * as Icon from 'react-feather';
import { getCookie } from '../utils/localStorage';

import Logo from '../public/images/logo-default.svg';

export default function Sidebar({ isOpen, toggleSidebar }){
  const router = useRouter();
  const [uid, setUid] = useState(false);

  useEffect(() => {
    async function fetchUid() {
      const uid = getCookie('uid');
      setUid(uid);
    }  
    fetchUid();
  }, []);

  // Aktif sayfanın URL'sini al
  const currentPath = router.pathname;

  return(
    <>
      <div className={`app-sidebar ${isOpen ? 'open' : ''}`}>
        <div className="sidebar-logo d-flex justify-content-center">
          <Link prefetch={false} href="/">
            <Image
              src={Logo}
              alt='Logo'
              quality={100}
              title="Yatırım Nedir: Temettü Hisseleri - Yatırım Fonları - BES Fonları Analiz"
            />
          </Link>
        </div>

        <div className="sidebar-menu custom-scroll pt-4">
          <Scrollbars style={{ height: 'calc(100% - 155px)'}}>
            <ul className="menu-group hide-desktop m-0 px-4 mb-4">
              <li className="sub-menu">
                <ul className="sub-menu-group">
                  <li>
                    <Link prefetch={false} href="/mobile-app"><span>Mobil Uygulama <Badge pill bg="success">Yeni</Badge></span></Link>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="menu-group m-0 px-2 mb-4">
              <li className={`sub-menu ${currentPath === '/temettu-hisseleri' || currentPath === '/temettu-takvimi' || currentPath === '/hisse-analiz/[slug]' || currentPath === '/en-cok-incelenen-hisseler' ? 'active' : ''}`}>
                <Link prefetch={false} href="#" className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <span>Temettü Hisseleri</span>
                  </div>
                </Link>
                <ul className="sub-menu-group">
                  <li className={currentPath === '/temettu-hisseleri' ? 'active' : ''}>
                    <Link prefetch={false} href="/temettu-hisseleri"><span>Genel Temettü Analiz</span></Link>
                  </li>
                  <li className={currentPath === '/temettu-takvimi' ? 'active' : ''}>
                    <Link prefetch={false} href="/temettu-takvimi"><span>Temettü Takvimi</span></Link>
                  </li>
                  <li className={currentPath === '/en-cok-incelenen-hisseler' ? 'active' : ''}>
                    <Link prefetch={false} href="/en-cok-incelenen-hisseler"><span>En Çok İncelenen Hisseler</span></Link>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="menu-group m-0 px-2 mb-4">
              <li className={`sub-menu ${currentPath === '/genel-fon-analiz' || currentPath === '/fon-filtreleme' || currentPath === '/fon-karsilastirma' || currentPath === '/dun-yatirimci-sayisi-en-cok-artan-fonlar' || currentPath === '/dun-yatirimci-sayisi-en-cok-dusen-fonlar' || currentPath === '/fon-analiz/[slug]' ? 'active' || currentPath === '/fon-getiri-hesaplama' || currentPath === '/en-cok-incelenen-fonlar' || currentPath === '/ceyreklik-fon-getirileri' || currentPath === '/temettu-odeyen-fonlar' || currentPath === '/fon-portfoy-yonetim-sirketleri' : ''}`}>
                <Link href="#" className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <span>Yatırım Fonları</span>
                  </div>
                </Link>
                <ul className="sub-menu-group">
                  <li className={currentPath === '/genel-fon-analiz' ? 'active' : ''}>
                    <Link prefetch={false} href="/genel-fon-analiz"><span>Genel Fon Analiz</span></Link>
                  </li>
                  <li className={currentPath === '/fon-filtreleme' ? 'active' : ''}>
                    <Link prefetch={false} href="/fon-filtreleme"><span>Fon Filtreleme</span></Link>
                  </li>
                  <li className={currentPath === '/fon-karsilastirma' ? 'active' : ''}>
                    <Link prefetch={false} href="/fon-karsilastirma"><span>Fon Karşılaştırma</span></Link>
                  </li>
                  <li className={currentPath === '/fon-portfoy-yonetim-sirketleri' ? 'active' : ''}>
                    <Link prefetch={false} href="/fon-portfoy-yonetim-sirketleri"><span>Portföy Yönetim Şirketleri</span></Link>
                  </li>
                  <li className={currentPath === '/dun-yatirimci-sayisi-en-cok-artan-fonlar' ? 'active' : ''}>
                    <Link prefetch={false} href="/dun-yatirimci-sayisi-en-cok-artan-fonlar"><span>Yatırımcı Sayısı Artanlar</span></Link>
                  </li>
                  <li className={currentPath === '/dun-yatirimci-sayisi-en-cok-dusen-fonlar' ? 'active' : ''}>
                    <Link prefetch={false} href="/dun-yatirimci-sayisi-en-cok-dusen-fonlar"><span>Yatırımcı Sayısı Düşenler</span></Link>
                  </li>
                  <li className={currentPath === '/en-cok-incelenen-fonlar' ? 'active' : ''}>
                    <Link prefetch={false} href="/en-cok-incelenen-fonlar"><span>En Çok İncelenen Fonlar</span></Link>
                  </li>
                  <li className={currentPath === '/fon-getiri-hesaplama' ? 'active' : ''}>
                    <Link prefetch={false} href="/fon-getiri-hesaplama"><span>Fon Getiri Hesaplama</span></Link>
                  </li>
                  <li className={currentPath === '/ceyreklik-fon-getirileri' ? 'active' : ''}>
                    <Link prefetch={false} href="/ceyreklik-fon-getirileri"><span>Çeyreklik Fon Getirileri</span></Link>
                  </li>
                  <li className={currentPath === '/temettu-odeyen-fonlar' ? 'active' : ''}>
                    <Link prefetch={false} href="/temettu-odeyen-fonlar"><span>Temettü Ödeyen Fonlar</span></Link>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="menu-group m-0 px-2 mb-4">
              <li className={`sub-menu ${currentPath === '/genel-bes-fon-analiz' || currentPath === '/bes-fon-filtreleme' || currentPath === '/bes-fon-karsilastirma' || currentPath === '/dun-yatirimci-sayisi-en-cok-artan-bes-fonlari' || currentPath === '/dun-yatirimci-sayisi-en-cok-dusen-bes-fonlari' || currentPath === '/bes-fon-analiz/[slug]' || currentPath === '/en-cok-incelenen-bes-fonlari' || currentPath === '/ceyreklik-bes-fon-getirileri' ? 'active' : ''}`}>
                <Link href="#" className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <span>BES Fonları</span>
                  </div>
                </Link>
                <ul className="sub-menu-group">
                  <li className={currentPath === '/genel-bes-fon-analiz' ? 'active' : ''}>
                    <Link prefetch={false} href="/genel-bes-fon-analiz"><span>Genel BES Fon Analiz</span></Link>
                  </li>
                  <li className={currentPath === '/bes-fon-filtreleme' ? 'active' : ''}>
                    <Link prefetch={false} href="/bes-fon-filtreleme"><span>BES Fon Filtreleme</span></Link>
                  </li>
                  <li className={currentPath === '/bes-fon-karsilastirma' ? 'active' : ''}>
                    <Link prefetch={false} href="/bes-fon-karsilastirma"><span>BES Fon Karşılaştırma</span></Link>
                  </li>
                  <li className={currentPath === '/dun-yatirimci-sayisi-en-cok-artan-bes-fonlari' ? 'active' : ''}>
                    <Link prefetch={false} href="/dun-yatirimci-sayisi-en-cok-artan-bes-fonlari"><span>Yatırımcı Sayısı Artanlar</span></Link>
                  </li>
                  <li className={currentPath === '/dun-yatirimci-sayisi-en-cok-dusen-bes-fonlari' ? 'active' : ''}>
                    <Link prefetch={false} href="/dun-yatirimci-sayisi-en-cok-dusen-bes-fonlari"><span>Yatırımcı Sayısı Düşenler</span></Link>
                  </li>
                  <li className={currentPath === '/en-cok-incelenen-bes-fonlari' ? 'active' : ''}>
                    <Link prefetch={false} href="/en-cok-incelenen-bes-fonlari"><span>En Çok İncelenen BES Fonları</span></Link>
                  </li>
                  <li className={currentPath === '/ceyreklik-bes-fon-getirileri' ? 'active' : ''}>
                    <Link prefetch={false} href="/ceyreklik-bes-fon-getirileri"><span>Çeyreklik BES Fon Getirileri</span></Link>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="menu-group hide-desktop m-0 px-2 mb-4">
              <li className="sub-menu">
                <Link href="#" className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <span>Diğer</span>
                  </div>
                </Link>
                <ul className="sub-menu-group">
                  <li>
                    <Link prefetch={false} href="https://forms.gle/F9kKYda6hAVdUkCGA" target="_blank"><span>İstek ve Hata Bildirimi</span></Link>
                  </li>
                  <li>
                    <Link prefetch={false} href="/version"><span>Yeni Özellikler</span></Link>
                  </li>
                  <li>
                    <Link prefetch={false} href="/blog"><span>Blog</span></Link>
                  </li>
                  <li>
                    <Link prefetch={false} href="/iletisim"><span>İletişim</span></Link>
                  </li>
                </ul>
              </li>
            </ul>

            <div className='social'>
              <Link prefetch={false} href='https://www.youtube.com/@YatirimNedir/' target='_blank' className='youtube'>
                <Icon.Youtube size={18} />
              </Link>
              <Link prefetch={false} href='https://x.com/YatirimNedir' target='_blank' className='twitter'>
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M18.2048 2.25H21.5128L14.2858 10.51L22.7878 21.75H16.1308L10.9168 14.933L4.95084 21.75H1.64084L9.37084 12.915L1.21484 2.25H8.04084L12.7538 8.481L18.2048 2.25ZM17.0438 19.77H18.8768L7.04484 4.126H5.07784L17.0438 19.77Z"></path></svg>
              </Link>
              <Link prefetch={false} href='https://www.instagram.com/YatirimNedir' target='_blank' className='instagram'>
                <Icon.Instagram size={18}/>
              </Link>
            </div>
          </Scrollbars>
        </div>

        <div className="sidebar-footer d-flex align-items-center justify-content-center">
          <Link prefetch={false} href="/cekince-bildirimi" className="sidebar-footer-button">Yasal Uyarı</Link>
        </div>
      </div>

      <div className={`menu-backdrop ${isOpen ? 'active' : ''}`} onClick={toggleSidebar}></div>
    </>
  )
}